import React, { FC } from "react";
import Image from "next/image";
// types
import { IFooterProps } from "src/components/ui/Footer/footer.types";
// components
import Link from "src/components/ui/Link";
import Logo from "src/components/ui/Logo/logo";
// const
import { FOOTER } from "src/constants/dynamic";
import { EVariants } from "src/constants/variants";
// hooks
import { useAppSelector } from "src/hooks/useRedux";
// redux
import { dynamicStateFieldSelector } from "src/redux/slices";
// theme
import {
  IDynamicFooter,
  IDynamicFooterCompany,
  IDynamicFooterCompanyInfo,
  IDynamicFooterResources,
  IDynamicFooterSocial,
} from "src/types/dynamic/footer";
import {
  BlockWrapper,
  FooterContainer,
  FooterSubhead,
  FooterText,
  WideBlockWrapper,
} from "./footer.styles";
import { Gradient } from "../common";

const Footer: FC<IFooterProps> = ({ styles, variant }) => {
  const data = useAppSelector(dynamicStateFieldSelector<IDynamicFooter>(FOOTER));
  return (
    <FooterContainer style={styles} variant={variant}>
      {!variant && <Gradient alignSelf="end" />}
      <WideBlockWrapper>
        <Link href="/">
          <Logo variant={!variant ? EVariants.SECONDARY : EVariants.PRIMARY} />
        </Link>
      </WideBlockWrapper>

      <BlockWrapper>
        <FooterSubhead variant={variant}>Social</FooterSubhead>
        {(data?.Social || []).map((item: IDynamicFooterSocial) => (
          <FooterText
            as="a"
            key={item.id}
            href={item.url}
            target={item.new_tab ? "_blank" : "_self"}
            variant={variant}
          >
            <Image width={16} height={16} src={item.icon.url} loader={({ src }) => src} />
            {item.title}
          </FooterText>
        ))}
      </BlockWrapper>

      <BlockWrapper>
        <FooterSubhead variant={variant}>Company</FooterSubhead>
        {(data?.Company || []).map((item: IDynamicFooterCompany) => (
          <FooterText
            as="a"
            key={item.id}
            href={item.url}
            target={item.new_tab ? "_blank" : "_self"}
            variant={variant}
          >
            {item.title}
          </FooterText>
        ))}
      </BlockWrapper>

      <WideBlockWrapper>
        <FooterSubhead variant={variant}>Resources</FooterSubhead>
        {(data?.Resources || []).map((item: IDynamicFooterResources) => (
          <FooterText
            as="a"
            key={item.id}
            href={item.url}
            target={item.new_tab ? "_blank" : "_self"}
            variant={variant}
          >
            {item.title}
          </FooterText>
        ))}
      </WideBlockWrapper>

      <WideBlockWrapper>
        {(data?.Company_info || []).map((item: IDynamicFooterCompanyInfo) => (
          <FooterText key={item.id} variant={variant}>
            {item.text}
          </FooterText>
        ))}
      </WideBlockWrapper>
    </FooterContainer>
  );
};

export default Footer;
