import React from "react";
// libs
import NextImage from "next/image";
// types
import { EVariants } from "src/constants/variants";
import { ILogoProps } from "./logo.types";

const LogoComponent: React.FC<ILogoProps> = ({ variant }) => {
  const getCompanyLogo = () => {
    if (variant === EVariants.PRIMARY) {
      return "/logo/logo.svg";
    }

    if (variant === EVariants.SECONDARY) {
      return "/logo/logoWhite.svg";
    }

    return "/logo/logo.svg";
  };

  return <NextImage priority src={getCompanyLogo()} width={58} height={47} alt="PetsApp" />;
};

export default LogoComponent;
