import React, { FC } from "react";
// libs
import NextImage from "next/image";
import { components } from "react-select";
import take from "lodash/take";
// components
import Select from "src/components/ui/Select";
// types
import { Props } from "react-select";
// theme
import { typography, colors } from "src/theme";
import { ImageContainer, SpeciesContainer } from "./styles";
import { useRouter } from "next/router";
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";

const ValueContainer = ({ children, ...props }: any) => {
  const isMobile = typeof window !== "undefined" && window.innerWidth < 420;
  const valuesToDisplay = isMobile ? 1 : 4;

  return (
    <components.ValueContainer {...props}>
      {props.hasValue && take(children[0], valuesToDisplay)}
      {props.hasValue && children[0].length > valuesToDisplay && "..."}
      {!props.hasValue && children}
    </components.ValueContainer>
  );
};

const Control = ({ children, ...props }: any) => {
  return (
    <components.Control {...props}>
      <ImageContainer>
        <NextImage src="/images/Home/vector.svg" width={18} height={12} />
      </ImageContainer>
      {children}
    </components.Control>
  );
};

const SpeciesList: FC<Props> = (props) => {
  const { pathname } = useRouter();

  return (
    <SpeciesContainer
      onClick={() => amplitudeEvent(formatEventName(pathname, "search", "filters"))}
    >
      <Select
        // @ts-ignore
        isMulti
        hideSelectedOptions={false}
        components={{
          ValueContainer,
          Control,
        }}
        styles={{
          control: (base) => {
            return {
              ...base,
              display: "inline-flex",
              minWidth: "220px",
              border: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
              outline: "none",
              "&:hover": {
                border: "none",
                boxShadow: "none",
              },
              fontFamily: typography.fontFamily,
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 20,
              color: colors.grey,
              whiteSpace: "nowrap",
              width: "100%",
              ...(props.selectStyles?.control || {}),
            };
          },
          indicatorsContainer: (base) => {
            return {
              ...base,
              ...(props.selectStyles?.indicator || {}),
            };
          },
          valueContainer: (base) => {
            return {
              ...base,
              flexWrap: "nowrap",
              width: "100%",
            };
          },
        }}
        closeMenuOnSelect={false}
        {...props}
      />
    </SpeciesContainer>
  );
};

export default SpeciesList;
