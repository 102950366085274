// libs
import styled from "styled-components";
import Link from "next/link";
// theme
import { colors, typography } from "src/theme";
import * as SearchInputStyles from "src/components/ui/SearchInput/styles";
// const
import device from "src/constants/media";
import { EVariants } from "src/constants/variants";

export const Nav = styled.div<{ backgroundColor: string; stickyNav: boolean }>`
  background-color: ${({ backgroundColor }) => backgroundColor}E6;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ stickyNav }) => (stickyNav ? "fixed" : "relative")};
  z-index: 1000;
  min-width: 100vw;

  @media ${device.tablet} {
    transition: 0.8s all ease;
  }
`;
export const NavbarContainer = styled.div<{ stickyNav: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ stickyNav }) => (stickyNav ? "1096px" : "auto")};

  @media ${device.laptopS} {
    padding: ${({ stickyNav }) => (stickyNav ? "18px 0" : "18px 24px")};
  }
`;
export const NavMenu = styled.ul`
  display: none;

  @media ${device.tablet} {
    display: flex;
    gap: 20px;
    align-items: baseline;
    list-style: none;
    text-align: center;
    flex-direction: row;
  }
  @supports (-moz-appearance: none) {
    align-items: center;
  }
`;

export const DesktopSearchInputWrapper = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    list-style: none;
    flex-direction: row;
    border: 1px solid ${colors.greyLight};
    border-radius: 8px;
  }

  @media ${device.laptop} {
    width: 600px;

    ${SearchInputStyles.Container} {
      width: 100%;
    }

    ${SearchInputStyles.InputContainer} {
      width: 215px;
    }

    #InputComponent_Wrapper {
      border: none;
    }
  }

  @media ${device.laptopL} {
    width: 872px;

    ${SearchInputStyles.Container} {
      width: 100%;
    }

    ${SearchInputStyles.InputContainer} {
      width: 100%;
    }

    #InputComponent_Wrapper {
      border: none;
    }
  }
`;
export const DesktopSearchInputSearchBar = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    list-style: none;
    flex-direction: row;
  }
`;

export const NavLogo = styled.div`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  text-align: center;
`;

export const MobileMenu = styled.div`
  display: flex;

  @media ${device.tablet} {
    display: none;
  }
`;
export const NavLink = styled.p<{ color: string; underline?: boolean }>`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;

  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  color: ${({ color }) => color};
  white-space: nowrap;
  border-bottom: ${({ underline }) => `2px solid ${underline ? colors.white : "transparent"}`};

  &.active {
    border-bottom: 3px solid ${colors.white};
  }
`;
export const ModalContainer = styled.div`
  position: absolute;
  width: 266px;
  height: 115px;
  padding: 22px 16px;
  right: 0;
  top: 60px;
  border-radius: 8px;
  background: ${colors.white};
  z-index: 10;
`;
export const TextContainer = styled.div`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: start;
  color: ${colors.greyDark};
`;
export const LineContainer = styled.div`
  height: 1px;
  margin: 15px 0;
  background: ${colors.greyVeryLight};
`;
export const ButtonContainer = styled.div`
  position: relative;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const SearchIconWrapper = styled.div`
  background: ${colors.white};
  border: 2px solid ${colors.greyVeryLight};
  box-sizing: border-box;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlagButton = styled.button<{ variant?: EVariants }>`
  align-self: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  margin: 0;
  min-height: 48px;
  padding: 8px;
  padding-bottom: 10px;
  place-items: center;
  transition: 0.3s all ease;

  :hover {
    background-color: ${({ variant }) =>
      variant === EVariants.SECONDARY ? colors.mainLight : colors.mainDark};
  }
`;

export const CountrySwitchContainer = styled.div`
  align-self: end;
  position: relative;
`;
