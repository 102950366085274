import React, { FC } from "react";
// libs
import NextImage from "next/image";
import { Content, Search, ImageContainer, Line, Species } from "./SearchBarPreview.styles";

interface ISearchBarPreview {
  searchTitle: string;
  onClick: () => void;
}

const SearchBarPreview: FC<ISearchBarPreview> = ({ searchTitle, onClick }) => {
  return (
    <Content onClick={onClick}>
      <Search>{searchTitle}</Search>
      <Line />
      <Species>Any species</Species>
      <ImageContainer>
        <NextImage width={40} height={40} src="/illustrations/search.svg" />
      </ImageContainer>
    </Content>
  );
};

export default SearchBarPreview;
