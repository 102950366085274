// libs
import axios from "src/libs/axios";
// const
import { ENDPOINTS } from "src/constants/endpoints";
// utils
import { getCountry } from "src/utils/countries";
import { getStringParams } from "src/utils/url";
// types
import { ParsedUrlQuery } from "querystring";
import { TClinicDetailsSearch } from "src/types";

interface IFetchSearchClinicsArgs {
  pathname: string;
  query: ParsedUrlQuery;
  locale: string;
}

interface ISearchClinicsByCityArgs {
  query: ParsedUrlQuery;
  locale: string;
  formattedFilters: ParsedUrlQuery;
}

interface ISearchClinicsByCoordinatesArgs {
  query: ParsedUrlQuery;
  locale: string;
  formattedFilters: ParsedUrlQuery;
}

interface IFetchSearchClinicsResponse {
  clinics: TClinicDetailsSearch[];
  count: number;
}

type TFetchSearchClinics = (args: IFetchSearchClinicsArgs) => Promise<IFetchSearchClinicsResponse>;
type TSearchClinicsByCity = (
  args: ISearchClinicsByCityArgs,
) => Promise<IFetchSearchClinicsResponse>;
type TSearchClinicsByCoordinates = (
  args: ISearchClinicsByCoordinatesArgs,
) => Promise<IFetchSearchClinicsResponse>;

const searchClinicsByCity: TSearchClinicsByCity = async ({ query, locale, formattedFilters }) => {
  /* localesToReplaceDashes - it's a temporary solution to replace dash with space
     because the server side accepts parameters for Canada only in this form */
  const localesToReplaceDashes = ["en-CA", "fr-CA", "en-IE"];
  let area = query.area?.[2];
  let city = query.area?.[1];
  let state = query.area?.[0];
  if (localesToReplaceDashes.includes(locale)) {
    area = area?.replace(" ", "-");
    city = city?.replace(" ", "-");
    state = state?.replace("-", " ");
  }
  /* temporary solution for USA because data from backend is not consistent */
  if (["en-US"].includes(locale)) {
    area = area?.replace(" ", "-");
    city = city?.replace(" ", "-");
    state = state?.replace("-", " ");
  }
  const areaParams = {
    ...(area && { area }),
    ...(city && { city }),
    ...(state && { state }),
    country: getCountry(locale, "locale")?.name,
  };

  const clinicsParams = getStringParams({
    ...areaParams,
    ...formattedFilters,
    _start: query._start || "0",
  });

  const countParams = getStringParams({
    ...areaParams,
    ...formattedFilters,
  });

  try {
    const [
      { data: clinics },
      {
        data: { count },
      },
    ] = await Promise.all([
      axios(`${ENDPOINTS.GET.searchClinicsGeo}?${clinicsParams}`),
      axios(`${ENDPOINTS.GET.searchClinicsGeoCount}?${countParams}`),
    ]);

    if (clinics.error) return { clinics: [], count: 0 };

    return { clinics, count };
  } catch (error) {
    let errorMessage = "Failed to searchClinicsByCity";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.log(errorMessage);
    return { clinics: [], count: 0 };
  }
};

const searchClinicsByCoordinates: TSearchClinicsByCoordinates = async ({
  query,
  locale,
  formattedFilters,
}) => {
  if (!query.ne || !query.sw) return { clinics: [], count: 0 };

  // 4 corners example:
  // ne: ["-75.1783", "41.7067"]
  // nw: ["-92.9921", "41.7067"]
  // se: ["-75.1783", "34.8491"]
  // sw: ["-92.9921", "34.8491"]

  const commonParams = {
    ne_lng: query.ne[0],
    ne_lat: query.ne[1],
    nw_lng: query.sw[0],
    nw_lat: query.ne[1],
    se_lng: query.ne[0],
    se_lat: query.sw[1],
    sw_lng: query.sw[0],
    sw_lat: query.sw[1],
    geo_order: query.geo_order,
    country: getCountry(locale, "locale")?.name,
  };

  const clinicsParams = getStringParams({
    ...commonParams,
    ...formattedFilters,
    _start: query._start || "0",
  });

  const countParams = getStringParams({
    ...commonParams,
    ...formattedFilters,
  });

  try {
    const [clinics, count] = await Promise.all([
      axios(`${ENDPOINTS.GET.searchClinics}?${clinicsParams}`),
      axios(`${ENDPOINTS.GET.searchClinicsCount}?${countParams}`),
    ]);
    return { clinics: clinics.data, count: count.data.count };
  } catch (error) {
    let errorMessage = "Failed to searchClinicsByCoordinates";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.log(errorMessage);
    return { clinics: [], count: 0 };
  }
};

const SEARCH_CLINICS_FILTERS_KEYS = ["services", "species_items"];

// eslint-disable-next-line import/prefer-default-export
export const fetchSearchClinics: TFetchSearchClinics = async ({ pathname, query, locale }) => {
  /** COMMON START */
  const filters: ParsedUrlQuery = Object.keys(query).reduce((acc, key) => {
    if (SEARCH_CLINICS_FILTERS_KEYS.includes(key)) {
      const value = typeof query[key] === "string" ? [query[key]] : query[key];
      const formattedValue = (value as string[])?.map((k) => k.replace("-", " "));
      acc[key] = formattedValue;
      return acc;
    }

    return acc;
  }, {} as ParsedUrlQuery);

  // add [key].name for each search filter
  const formattedFilters = Object.fromEntries(
    Object.entries(filters).map(([key, value]) => [`${key}.name`, value]),
  );
  /** COMMON FINISH */

  try {
    let response: IFetchSearchClinicsResponse = { clinics: [], count: 0 };

    let queryArea = query.area;

    // for veterinarian/service/:service_name
    if (query.area?.includes("service")) {
      query.services = [query.area?.[1]];
      queryArea = ["search"];
    }

    // if not '/veterinarian/[...area]'
    if (queryArea && queryArea.length > 0 && !queryArea?.includes("search")) {
      response = await searchClinicsByCity({ query, locale, formattedFilters });
    } else if (query.ne && query.se) {
      response = await searchClinicsByCoordinates({ query, locale, formattedFilters });
    }

    // if pathname is '/veterinarian/[...area]' => search by coordinates
    // if count is 0 after search by city
    if (query.area && query.area.length > 0 && +response?.clinics.length === 0) {
      response = await searchClinicsByCoordinates({ query, locale, formattedFilters });
    }

    return response;
  } catch (error) {
    let errorMessage = "Failed to fetchSearchClinics";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.log(errorMessage);
    return { clinics: [], count: 0 };
  }
};
