// libs
import styled from "styled-components";
import { FieldErrors } from "react-hook-form";
// theme
import { colors, typography } from "src/theme";

interface InputType {
  filled?: FieldErrors;
}

export const Context = styled.div<InputType>`
  position: relative;
`;

export const InputWrapper = styled.div<InputType>`
  border: ${({ filled }) => (filled ? "1px solid red" : `1px solid ${colors.greyLight}`)};
  border-radius: 8px;
  display: flex;
  height: 48px;
  overflow: hidden;
  width: 100%;
  @media (min-width: 501px) {
    width: 360px;
  }
`;
export const ButtonContainer = styled.div`
  background-color: ${colors.greyVeryLight};
  border-right: 1px solid ${colors.greyLight};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  padding: 12px;
  place-content: space-between;
  place-items: center;
  position: relative;
  width: fit-content;
  :hover {
    background-color: ${colors.mainLight};
  }
`;
export const FlagContainer = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  min-height: 24px;
  min-width: 24px;
  overflow: hidden;
`;
export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 12px;
  min-height: 7px;
`;
export const InputContainer = styled.div`
  background: ${colors.white};
  width: 100%;

  #InputComponent_Wrapper {
    padding-left: 12px;
    border: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const CountryContainer = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? colors.mainLight : "transparent")};
  cursor: pointer;
  display: flex;
  gap: 12px;
  padding: 8px 12px;
  padding-right: 16px;
  width: 100%;
  place-items: center;

  :hover {
    background-color: ${({ selected }) => (selected ? colors.mainLight : colors.mainVeryLight)};
  }
`;
