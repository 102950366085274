import React from "react";
import device from "src/constants/media";
import { colors } from "src/theme";
import { Action, Body } from "src/theme/typography";
import styled from "styled-components";

export const FlexRow = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap || "0"};
  place-items: center;
  width: 100%;
`;

export const FlexColumn = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || "0"};
  width: 100%;
`;

export const SectionCard = styled.section`
  background: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 8px 64px -32px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-inline: auto;
  margin-top: 80px;
  max-width: 1096px;
  padding: 40px 24px 64px;
  place-items: center;
  position: relative;
  text-align: center;

  @media ${device.tablet} {
    padding: 120px 48px;
  }
  @media ${device.laptop} {
    padding: 80px 112px;
    margin-top: 180px;
  }
`;

export const Pill = styled(Action)`
  background-color: ${colors.orangeLight};
  color: ${colors.orange};
  border-radius: 8px;
  display: flex;
  height: 56px;
  place-items: center;
  min-width: fit-content;
  padding: 12px;
`;

export const SmallCard = styled(FlexColumn)<{ backgroundColor?: string }>`
  padding: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.greyVeryLight};
  border-radius: 8px;
`;

export const Line = styled.hr`
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${colors.greyLight};
`;

export const DropdownCard = styled.div<{ position?: "left" | "right" }>`
  background: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 8px 64px -32px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  min-width: max-content;
  position: absolute;
  ${({ position }) =>
    position === "right"
      ? `
  right: 0;
  `
      : `
  left: 0;
  `}
  overflow: hidden;
  padding-block: 6px;
  top: 56px;
  z-index: 1;
`;

const GradientWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  inset: 0;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: visible;
`;

export const GradientInner = styled.div<{ alignSelf: string }>`
  height: 40vh;
  min-width: 100vw;
  background: ${({ alignSelf }) => `linear-gradient(
    ${alignSelf === "end" ? 180 : 0}deg,
    rgba(45, 106, 239, 1) 0%,
    rgba(29, 86, 210, 1) 35%,
    rgba(13, 66, 182, 1) 100%
  )`};
  align-self: ${({ alignSelf }) => alignSelf};
`;
export const Gradient = ({ alignSelf }: { alignSelf: "start" | "end" }) => (
  <GradientWrapper>
    <GradientInner alignSelf={alignSelf} />
  </GradientWrapper>
);
