import devices from "src/constants/media";
import { EVariants } from "src/constants/variants";
import { colors } from "src/theme/colors";
import { Footnote, Subhead } from "src/theme/typography";
import styled from "styled-components";

export const FooterContainer = styled.div<{ variant?: EVariants }>`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  justify-content: center;
  margin-inline: auto;
  margin-top: 80px;
  max-width: 1096px;
  padding-inline: 24px;
  padding-bottom: 80px;

  @media ${devices.laptop} {
    padding-block: 112px;
    padding-inline: 24px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  @media ${devices.laptopS} {
    padding-inline: ${({ variant }) => (!variant ? 0 : "24px")};
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const WideBlockWrapper = styled(BlockWrapper)`
  grid-column: span 2;

  @media ${devices.laptop} {
    grid-column: span 1;
  }
`;

export const FooterSubhead = styled(Subhead)<{ variant?: EVariants }>`
  color: ${({ variant }) => (!variant ? colors.white : colors.greyDark)};
  margin-bottom: 6px;
`;

export const FooterText = styled(Footnote)<{ variant?: EVariants }>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  color: ${({ variant }) => (!variant ? colors.white : colors.greyDark)};

  :hover {
    color: ${colors.mainVeryLight};
  }
`;
