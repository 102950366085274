import styled from "styled-components";
import { colors } from "src/theme/colors";
import { typography } from "src/theme";

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 296px;
  height: 48px;
  padding: 0 4px 0 16px;
  border: 1px solid ${colors.greyLight};
  box-sizing: border-box;
  border-radius: 8px;
`;
export const Search = styled.p`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.greyDark};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30%;
`;
export const ImageContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
export const Line = styled.div`
  width: 1px;
  height: 20px;
  background: ${colors.greyLight};
`;
export const Species = styled.p`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.greyDark};
`;
