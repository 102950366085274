import styled from "styled-components";
import { colors } from "src/theme/colors";
import device from "src/constants/media";

export const SpeciesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  flex-grow: 1;
  padding-left: 8px;
  cursor: pointer;
  width: 100%;
  border-top: 1px solid ${colors.greyVeryLight};
  border-bottom: 1px solid ${colors.greyVeryLight};

  @media ${device.laptop} {
    justify-content: left;
    padding-left: 0;
    border: none;
  }
`;
export const ImageContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
