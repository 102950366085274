import React, { FC } from "react";
// components
import Header from "src/components/ui/Navbar/navbar";
import Footer from "src/components/ui/Footer/footer";
// types
import { ILayoutProps } from "./layout.types";

const Layout: FC<ILayoutProps> = ({ headerProps, footerProps, children }) => (
  <>
    <Header {...headerProps} />
    {children}
    <Footer {...footerProps} variant={headerProps?.variant} />
  </>
);

export default Layout;
