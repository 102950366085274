import React, { FC, useState, useRef, useEffect } from "react";
// libs
import NextImage from "next/image";
import router, { useRouter } from "next/router";
// components
import Link from "src/components/ui/Link";
import Logo from "src/components/ui/Logo";
import Button from "src/components/ui/button";
import MainInput from "src/components/containers/home/sections/MainInput";
// hooks
import { useAppSelector, useModal, useOnClickOutside } from "src/hooks";
// redux
import { dynamicStateFieldSelector, permissionSelector } from "src/redux/slices";
import {
  VariablesState,
  variablesStateKeySelector,
  variablesStateSelector,
} from "src/redux/slices/variables";
// const
import { HEADER } from "src/constants/dynamic";
import { MENU_MODAL } from "src/constants/modals";
import { HIDE_GEOLOCATION_MODAL_ON_SEARCH_PAGE, SEARCH_AS_I_MOVE } from "src/constants/variables";
import * as permissions from "src/constants/permissions";
// theme
import { colors } from "src/theme";
// types
import { EVariants } from "src/constants/variants";
import {
  IDynamicHeader,
  IDynamicHeaderHeaderLinks,
  IDynamicHeaderSignInDropdown,
} from "src/types/dynamic/header";
// utils
import { formatSearchArea } from "src/utils/url";
import SearchBarPreview from "src/components/ui/SearchBar/SearchBarPreview";
import {
  Nav,
  NavMenu,
  NavbarContainer,
  NavLogo,
  MobileMenu,
  NavLink,
  ModalContainer,
  TextContainer,
  LineContainer,
  ButtonContainer,
  DesktopSearchInputWrapper,
  DesktopSearchInputSearchBar,
  SearchIconWrapper,
  ButtonsContainer,
  FlagButton,
  CountrySwitchContainer,
} from "./navbar.styles";
import { INavbarProps } from "./navbar.types";
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";
import { countries, CountryCode, getDomainCountryCode } from "../phoneInput/countries";
import { CountryContainer, FlagContainer } from "../phoneInput/PhoneInput.styles";
import { DropdownCard, Gradient } from "../common";
import { Body, Subhead } from "src/theme/typography";

const primaryStylesObj = {
  backgroundColor: "#134AC1",
  logoVariant: EVariants.SECONDARY,
  linksColor: colors.white,
  firstButtonVariant: EVariants.QUATERNARY,
  stickyNav: true,
};

const secondaryStylesObj = {
  backgroundColor: colors.white,
  logoVariant: EVariants.PRIMARY,
  linksColor: colors.greyDark,
  firstButtonVariant: EVariants.SECONDARY,
  stickyNav: false,
};

const getStyles = (variant?: EVariants) => {
  if (variant === EVariants.PRIMARY) return primaryStylesObj;
  if (variant === EVariants.SECONDARY) return secondaryStylesObj;

  return primaryStylesObj;
};

const Navbar: FC<INavbarProps> = ({ variant, showSearchInput }) => {
  const buttonSignIn: any = useRef();
  const { locale, pathname, query } = useRouter();
  const { backgroundColor, logoVariant, linksColor, firstButtonVariant, stickyNav } =
    getStyles(variant);
  const { openModal } = useModal();
  const data = useAppSelector(dynamicStateFieldSelector<IDynamicHeader>(HEADER));
  const geolocationPermission = useAppSelector(permissionSelector(permissions.GEOLOCATION));
  const hideGeolocationModal = useAppSelector(
    variablesStateKeySelector(HIDE_GEOLOCATION_MODAL_ON_SEARCH_PAGE),
  );
  const isGeolocationDenied = geolocationPermission === "denied";
  const VariablesStateSelected: VariablesState = useAppSelector(variablesStateSelector);
  const searchOnMove = VariablesStateSelected[SEARCH_AS_I_MOVE];
  const [inputIsOpen, setInputState] = useState<boolean>(false);
  const [openModalWindow, setOpenModalWindow] = useState<boolean>(false);
  const [countrySelectIsOpen, setCountrySelectIsOpen] = useState<boolean>(false);
  const countrySelectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(countrySelectRef, () => setCountrySelectIsOpen(false));
  const [domainCountryCode, setDomainCountryCode] = useState<CountryCode>("US");
  const [searchValue, setSearchValue] = useState<string>("");
  const [isSearchBarPreviewClick, setIsSearchBarPreviewClick] = useState<boolean>(false);
  const isQuerySearch = query.area?.includes("search");

  const openMenuModal = () => openModal(MENU_MODAL);
  const handleModalWindow = () => setOpenModalWindow(!openModalWindow);

  const closeModalWindow = (e: MouseEvent) => {
    setOpenModalWindow(false);
    if (e) {
      e.stopPropagation();
    }
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (!buttonSignIn.current.contains(event.target)) {
      closeModalWindow(event);
    }
  };

  const getSearchArea = (): string => {
    const isServiceUrlEnity = query.area?.includes("service");
    if (isQuerySearch || query.area === undefined || isServiceUrlEnity) return "";
    const area = [...(query.area as string[])];
    return formatSearchArea(area);
  };

  useEffect(() => {
    if (openModalWindow) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openModalWindow]);

  useEffect(() => {
    const searchArea = getSearchArea();
    if (searchArea) {
      setSearchValue(searchArea);
    } else if (searchOnMove) {
      setSearchValue("");
    } else {
      setSearchValue("");
    }
  }, [query, searchOnMove]);

  const toggleSearchInput = () => setInputState(!inputIsOpen);

  useEffect(() => {
    setDomainCountryCode(getDomainCountryCode(location.origin));
  }, []);

  const handleRedirect = (countryCode: CountryCode) => {
    amplitudeEvent(formatEventName(pathname, "nav_country_select", countryCode));
    let tld = ".com";

    switch (countryCode) {
      case "CA":
        tld = ".ca";
        break;
      case "IE":
        tld = ".ie";
        break;
      case "UK":
        tld = ".co.uk";
        break;
    }

    const redirectToUrl = `https://www.petsapp${tld}${pathname}`;

    if (process.env.NODE_ENV === "production") {
      router.push(redirectToUrl);
    } else {
      // setDomainCountryCode(countryCode);
    }
  };

  const renderSearchBar = () => {
    if (!isSearchBarPreviewClick) {
      return (
        <DesktopSearchInputSearchBar>
          <SearchBarPreview
            searchTitle={searchValue}
            onClick={() => setIsSearchBarPreviewClick(true)}
          />
        </DesktopSearchInputSearchBar>
      );
    }
    return (
      <DesktopSearchInputWrapper>
        <MainInput
          searchValue={searchValue}
          placeholder="For any species"
          hideGeoModal={hideGeolocationModal || isGeolocationDenied}
        />
      </DesktopSearchInputWrapper>
    );
  };

  return (
    <nav style={{ height: 88 }}>
      {!variant && <Gradient alignSelf="start" />}
      <Nav backgroundColor={backgroundColor} stickyNav={stickyNav}>
        <NavbarContainer stickyNav={stickyNav}>
          <NavLogo
            onClick={() => amplitudeEvent(formatEventName(pathname, "nav", "logo"))}
            style={{ width: "20%" }}
          >
            <Link href="/">
              <Logo variant={logoVariant} />
            </Link>
          </NavLogo>

          {showSearchInput && renderSearchBar()}
          <NavMenu>
            {(data?.header_links || []).map((item: IDynamicHeaderHeaderLinks) => (
              <Link href={item.url} key={item.id}>
                <NavLink
                  color={linksColor}
                  underline={pathname === item.url}
                  onClick={() => amplitudeEvent(formatEventName(pathname, "nav", item.url))}
                >
                  {item.title}
                </NavLink>
              </Link>
            ))}
          </NavMenu>

          <NavMenu style={{ width: "20%", placeContent: "flex-end" }}>
            <CountrySwitchContainer ref={countrySelectRef}>
              <FlagButton
                variant={variant}
                onClick={() => {
                  setCountrySelectIsOpen(!countrySelectIsOpen);
                  amplitudeEvent(formatEventName(pathname, "nav", "country_select"));
                }}
              >
                <FlagContainer>
                  <NextImage
                    width={24}
                    height={24}
                    src={
                      countries.filter((country) => country.countryCode === domainCountryCode)[0]
                        .flag
                    }
                  />
                </FlagContainer>
                <NextImage
                  src={!variant ? "/icons/chevron-down-white.svg" : "/icons/chevron-down.svg"}
                  width={14}
                  height={14}
                  layout="fixed"
                />
              </FlagButton>
              {countrySelectIsOpen && (
                <DropdownCard position="right">
                  <Subhead style={{ alignSelf: "start", padding: 12, paddingTop: 8 }}>
                    Select your country
                  </Subhead>
                  {countries.map((country) => {
                    const selected = domainCountryCode === country.countryCode;

                    return (
                      <CountryContainer
                        key={country.id}
                        selected={selected}
                        onClick={() => !selected && handleRedirect(country.countryCode)}
                      >
                        <FlagContainer>
                          <NextImage width={24} height={24} src={country.flag} />
                        </FlagContainer>
                        <Body textColor={colors.greyDark}>{country?.name}</Body>
                      </CountryContainer>
                    );
                  })}
                </DropdownCard>
              )}
            </CountrySwitchContainer>

            <ButtonsContainer>
              {pathname === "/" && (
                <Link href="/demo">
                  <Button
                    onClick={() => {
                      amplitudeEvent(formatEventName(pathname, "nav", "book_demo"));
                    }}
                    variant={EVariants.SECONDARY}
                  >
                    {locale === "en-US" ? "Schedule" : "Book"} a call
                  </Button>
                </Link>
              )}

              <ButtonContainer ref={buttonSignIn}>
                <Button
                  variant={firstButtonVariant}
                  onClick={() => {
                    handleModalWindow();
                    amplitudeEvent(formatEventName(pathname, "nav", "sign_in"));
                  }}
                >
                  Sign In
                </Button>
                {openModalWindow && (
                  <ModalContainer onClick={handleModalWindow}>
                    {(data?.sign_in_dropdown || []).map(
                      (item: IDynamicHeaderSignInDropdown, idx: number) => {
                        const buttonName = item.title
                          .toLowerCase()
                          .replace("as a ", "")
                          .replaceAll(" ", "_");

                        return (
                          <>
                            {idx !== 0 && <LineContainer />}
                            <Link href={item.url}>
                              <TextContainer
                                onClick={() =>
                                  amplitudeEvent(formatEventName(pathname, "nav", buttonName))
                                }
                              >
                                {item.title}
                              </TextContainer>
                            </Link>
                          </>
                        );
                      },
                    )}
                  </ModalContainer>
                )}
              </ButtonContainer>
            </ButtonsContainer>
          </NavMenu>

          <MobileMenu>
            {showSearchInput && (
              <SearchIconWrapper onClick={toggleSearchInput}>
                {inputIsOpen && (
                  <NextImage src="/icons/close-black.svg" width={14} height={14} layout="fixed" />
                )}
                {!inputIsOpen && (
                  <NextImage src="/icons/search.svg" width={18} height={18} layout="fixed" />
                )}
              </SearchIconWrapper>
            )}

            <Button variant={firstButtonVariant} onClick={openMenuModal}>
              Menu
            </Button>
          </MobileMenu>
        </NavbarContainer>
      </Nav>

      {inputIsOpen && (
        <MainInput
          searchValue={searchValue}
          placeholder="For any animal"
          mobileContainerStyles={{
            border: `1px solid ${colors.greyLight}`,
            padding: "0px 12px",
            borderRadius: 8,
          }}
        />
      )}
    </nav>
  );
};

export default Navbar;
