import React, { FC } from "react";
// libs
import Select, { Props } from "react-select";
// theme
import { colors, typography } from "src/theme";

const SelectComponent: FC<Props> = ({ components, styles, ...props }) => {
  return (
    <Select
      components={{
        IndicatorSeparator: () => null,
        ...components,
      }}
      styles={{
        container: (base) => ({
          ...base,
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          border: `1px solid ${colors.greyLight}`,
          borderRadius: "8px",
          height: "48px",
          fontFamily: typography.fontFamily,
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
        }),
        menu: (base) => ({
          ...base,
          marginTop: "13px",
          borderRadius: "0 0 8px 8px",
          border: `1px solid ${colors.greyLight}`,
          borderTop: "none",
          boxShadow: "none",
          overflow: "hidden",
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => ({
          ...base,
          fontFamily: typography.fontFamily,
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "28px",
          color: colors.greyDark,
          backgroundColor: isSelected ? colors.mainLight : colors.white,
          ":hover": {
            backgroundColor: isSelected ? colors.mainVeryLight : colors.greyVeryLight,
          },
        }),
        dropdownIndicator: (base) => ({
          ...base,
          color: `${colors.grey}`,
        }),
        placeholder: (base) => ({
          ...base,
          color: `${colors.greyDark}`,
        }),
        ...styles,
      }}
      {...props}
    />
  );
};

export default SelectComponent;
