import React, { FC } from "react";
// libs
import { getLatLng } from "react-places-autocomplete";
// components
import SearchInput from "src/components/ui/SearchInput";
// hooks
import { useRouter } from "next/router";
import { useAppDispatch, useModal, useAppSelector } from "src/hooks";
// redux
import { updateVariable } from "src/redux/slices/variables/reducer";
import { authUserGeometrySelector, authUserLocationSelector } from "src/redux/slices";
// utils
import { getCountry } from "src/utils/countries";
import { formatSearchUrl, formatSearchZoom } from "src/utils/url";
import { getBoundsByCenter } from "src/utils/map";
// const
import { GEOLOCATION_MODAL } from "src/constants/modals";
import { HIDE_GEOLOCATION_MODAL_ON_SEARCH_PAGE, SEARCH_AS_I_MOVE } from "src/constants/variables";
// types
import { ParsedUrlQueryInput } from "querystring";
import { ICountry } from "src/constants/countries";
import { IGeometry, LngLatBounds } from "src/constants/coordinates";
import {
  IAddress,
  COUNTRY_TYPES,
  STATE_TYPES,
  CITY_TYPES,
  AREA_TYPES,
  PLACE_TYPES,
  zoom as UtilsZoom,
  getCountryZoom,
  COUNTY_TYPES,
  IAddressItem,
} from "src/constants/map";
import { fetchSearchClinics } from "../../../../../utils/search";

interface IMainInput {
  searchValue?: string;
  placeholder?: string;
  mobileContainerStyles?: object;
  hideGeoModal?: boolean;
  onGeoModalClose?: () => void;
  onGeoModalSuccess?: () => void;
}

const MainInput: FC<IMainInput> = ({
  searchValue,
  placeholder,
  mobileContainerStyles,
  hideGeoModal,
  onGeoModalClose,
  onGeoModalSuccess,
}) => {
  const router = useRouter();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const userGeometry: IGeometry = useAppSelector(authUserGeometrySelector);
  const userLocation: string = useAppSelector(authUserLocationSelector);

  const userCountry: ICountry = getCountry(router.locale, "locale") as ICountry;
  const getAddressItemByTypes = (
    addressComponents: google.maps.GeocoderAddressComponent[],
    addressType: any,
  ) => {
    return addressComponents.find((item) =>
      item.types.some((type) => Object.values(addressType).includes(type)),
    );
  };

  const getCountyByPriority = (
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): IAddressItem => {
    const sortedCountyTypesByPriority = [
      COUNTY_TYPES.ADMIN_AREA_4,
      COUNTY_TYPES.ADMIN_AREA_3,
      COUNTY_TYPES.ADMIN_AREA_2,
    ];
    let result: IAddressItem;
    sortedCountyTypesByPriority.forEach((countyType) => {
      addressComponents.some((item) => {
        if (item.types.includes(countyType) && result === undefined) {
          result = item;
        }
      });
    });
    // @ts-ignore
    return result;
  };

  const getQueryAreaByAddresses = (
    addressComponents: google.maps.GeocoderAddressComponent[],
  ): IAddress => {
    const country = getAddressItemByTypes(addressComponents, COUNTRY_TYPES);
    const state = getAddressItemByTypes(addressComponents, STATE_TYPES);
    const city =
      getAddressItemByTypes(addressComponents, CITY_TYPES) ||
      getCountyByPriority(addressComponents);
    const area = getAddressItemByTypes(addressComponents, AREA_TYPES);
    return {
      country,
      state,
      city,
      area,
    };
  };

  const redirectToSearchPage = async (
    searchedPlace: google.maps.GeocoderResult | null,
    searchedSpecies: string[],
  ) => {
    // turn of the Search as I move checkbox on the map:
    dispatch(updateVariable({ name: SEARCH_AS_I_MOVE, value: false }));

    if (searchedPlace) {
      // TABLE 3 for available types in autocomplete search
      // https://developers.google.com/maps/documentation/places/web-service/supported_types

      const { country, state, city, area } = getQueryAreaByAddresses(
        searchedPlace.address_components,
      );
      const isCountry = searchedPlace.address_components.length === 1;
      let pathname = formatSearchUrl(
        isCountry ? country?.long_name : state?.long_name,
        city?.long_name,
        area?.long_name,
      );
      let zoom = formatSearchZoom(
        userCountry.short_name,
        state?.long_name,
        city?.long_name,
        area?.long_name,
      );

      // for Great Britain
      if (
        searchedPlace.address_components.find((el) =>
          el.types.includes(PLACE_TYPES.NATURAL_FEATURE),
        )
      ) {
        zoom = getCountryZoom(userCountry.short_name);
      }

      if (pathname === "/veterinarian") {
        // if selected place is country, then replace to /veterinarian/search
        pathname = "/veterinarian/search";
      }

      const center = await getLatLng(searchedPlace);

      let bounds: LngLatBounds;

      if (userLocation === searchedPlace.formatted_address) {
        // if search by my location, set another zoom
        bounds = getBoundsByCenter(userGeometry.center, UtilsZoom.service) as LngLatBounds;
      } else {
        // search by another location
        bounds = getBoundsByCenter(center, zoom) as LngLatBounds;
      }
      // don't use next bounds, there is not working map zoom, use getBoundsByCenter util
      // const bounds: LngLatBounds = {
      //   ne: {
      //     lng: searchedPlace?.geometry.bounds?.getNorthEast().lng() as number,
      //     lat: searchedPlace?.geometry.bounds?.getNorthEast().lat() as number,
      //   },
      //   sw: {
      //     lng: searchedPlace?.geometry.bounds?.getSouthWest().lng() as number,
      //     lat: searchedPlace?.geometry.bounds?.getSouthWest().lat() as number,
      //   },
      // }

      let query: ParsedUrlQueryInput = {
        ...router.query,
        _start: 0,
        geo_order: `${center.lat},${center.lng}`,
        ne: [bounds.ne.lng, bounds.ne.lat],
        sw: [bounds.sw.lng, bounds.sw.lat],
      };

      if (searchedSpecies) {
        query = {
          ...query,
          species_items: searchedSpecies,
        };
      }

      let method = router.push;
      if (router.pathname === "/veterinarian/[...area]") {
        // prevent push one more page to history
        // if we are on the same page
        method = router.replace;
      }

      const { count } = await fetchSearchClinics({
        pathname,
        query: { ...query, area: ["search"] },
        locale: router.locale || "en-US",
      });
      if (Number(count) === 0) {
        router.push("/no-results");
        return;
      }

      await method(
        {
          pathname: "/veterinarian/[...area]",
          query,
        },
        {
          pathname: pathname.replaceAll(" ", "_"),
          query,
        },
      );
    } else {
      const geolocationCallback = async (query: ParsedUrlQueryInput) => {
        if (searchedSpecies) {
          query = {
            ...query,
            species_items: searchedSpecies,
          };
        }

        let method = router.push;
        if (router.pathname === "/veterinarian/[...area]") {
          // prevent push one more page to history
          // if we are on the same page
          method = router.replace;
        }

        // prevent show geolocation modal if close modal without setting new state
        dispatch(updateVariable({ name: HIDE_GEOLOCATION_MODAL_ON_SEARCH_PAGE, value: true }));

        await method(
          {
            pathname: "/veterinarian/[...area]",
            query,
          },
          {
            pathname: "/veterinarian/search",
            query,
          },
        );
      };

      const successCallback = (userGeometry: IGeometry) => {
        // if search by my location, set another zoom
        const bounds: LngLatBounds = getBoundsByCenter(
          userGeometry.center,
          UtilsZoom.service,
        ) as LngLatBounds;

        const query: ParsedUrlQueryInput = {
          _start: 0,
          geo_order: `${userGeometry.center.lat},${userGeometry.center.lng}`,
          ne: [bounds.ne.lng, bounds.ne.lat],
          sw: [bounds.sw.lng, bounds.sw.lat],
        };

        geolocationCallback(query);
      };

      const denyCallback = (userGeometry: IGeometry) => {
        const query: ParsedUrlQueryInput = {
          _start: 0,
          geo_order: `${userGeometry.center.lat},${userGeometry.center.lng}`,
          ne: [userGeometry.bounds.ne.lng, userGeometry.bounds.ne.lat],
          sw: [userGeometry.bounds.sw.lng, userGeometry.bounds.sw.lat],
        };

        geolocationCallback(query);
      };

      if (hideGeoModal) {
        // if search by my location, set another zoom
        const bounds: LngLatBounds = getBoundsByCenter(
          userGeometry.center,
          UtilsZoom.def,
        ) as LngLatBounds;
        denyCallback({ ...userGeometry, bounds });
      } else {
        openModal(GEOLOCATION_MODAL, {
          onSuccess: successCallback,
          onDeny: denyCallback,
          onCloseCb: denyCallback,
        });
      }
    }
  };

  return (
    <SearchInput
      value={searchValue}
      onSearch={redirectToSearchPage}
      showGeoModal={!hideGeoModal}
      placeholder={placeholder}
      mobileContainerStyles={mobileContainerStyles}
      onGeoModalClose={onGeoModalClose}
      onGeoModalSuccess={onGeoModalSuccess}
    />
  );
};

export default MainInput;
