export type CountryCode = "CA" | "IE" | "UK" | "US";

export interface ICountries {
  id: number;
  flag: string;
  code: string;
  name: string;
  countryCode: CountryCode;
}

export const countries = [
  {
    id: 3,
    flag: "/countryFlags/united-states.svg",
    code: "+1",
    name: "United States",
    countryCode: "US" as CountryCode,
  },
  {
    id: 1,
    flag: "/countryFlags/united-kingdom.svg",
    code: "+44",
    name: "United Kingdom",
    countryCode: "UK" as CountryCode,
  },
  {
    id: 2,
    flag: "/countryFlags/ireland.svg",
    code: "+353",
    name: "Ireland",
    countryCode: "IE" as CountryCode,
  },
  {
    id: 4,
    flag: "/countryFlags/canada.svg",
    code: "+1",
    name: "Canada",
    countryCode: "CA" as CountryCode,
  },
  {
    id: 5,
    flag: "/countryFlags/australia.svg",
    code: "+61",
    name: "Australia",
    countryCode: "AU" as CountryCode,
  },
  {
    id: 6,
    flag: "/countryFlags/uae.svg",
    code: "+971",
    name: "United Arab Emirates",
    countryCode: "AE" as CountryCode,
  },
];

export const getDomainCountryCode: (url: string) => CountryCode = (url) => {
  if (url.includes(".co.uk")) {
    return "UK";
  }
  if (url.includes(".ie")) {
    return "IE";
  }
  if (url.includes(".ca")) {
    return "CA";
  }

  return "US";
};
