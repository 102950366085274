import React, { FC } from "react";
// libs
import Head from "next/head";
// redux
import { dynamicMetaRouteSelector } from "src/redux/slices/dynamic/selectors";
// hooks
import { useAppSelector } from "src/hooks";
import { useRouter } from "next/router";
// types

interface ICustomHead {
  title?: string;
  description?: string;
  og_title?: string;
  og_description?: string;
  og_image?: string;
}

const CustomHead: FC<ICustomHead> = (props) => {
  const router = useRouter();
  const { meta_tags } = useAppSelector(dynamicMetaRouteSelector(router.route));

  const title = meta_tags?.title || props.title || "PetsApp";
  const keywords = meta_tags?.keywords;
  const description = props.description || meta_tags?.description;
  const subject = meta_tags?.subject;
  const subtitle = meta_tags?.subtitle;
  const image = props.og_image || meta_tags?.og_image?.url;

  const ogTitle = props.og_title || meta_tags?.og_title || title;
  const ogDescription = props.og_description || meta_tags?.og_description || description;
  const url = process.env.HOST + router.asPath;

  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} />
      {keywords ? <meta name="keywords" content={keywords} /> : null}
      <meta name="language" content={router.locale} />
      <meta name="google-adsense-account" content="ca-pub-8483617149861979" />
      {description ? <meta name="description" content={description} /> : null}
      {subtitle ? <meta name="subtitle" content={subtitle} /> : null}
      {subject ? <meta name="subject" content={subject} /> : null}
      {image ? (
        <>
          <meta name="image" content={image} />
          <meta name="og:image" content={image} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:image" content={image} />
        </>
      ) : null}

      {ogTitle ? (
        <>
          <meta name="twitter:title" content={ogTitle} />
          <meta name="og:title" content={ogTitle} />
        </>
      ) : null}

      {url ? (
        <>
          <meta name="og:url" content={url} />
          <meta name="twitter:url" content={url} />
        </>
      ) : null}

      {ogDescription ? (
        <>
          <meta name="og:description" content={ogDescription} />
          <meta name="twitter:description" content={ogDescription} />
        </>
      ) : null}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@petsappltd" />
      {props.children}
    </Head>
  );
};

export default CustomHead;
