import React, { FC, useState, useRef, useEffect } from "react";
// libs
import { geocodeByAddress } from "react-places-autocomplete";
import dynamic from "next/dynamic";
import useSWR from "swr";
import axios from "src/libs/axios";
// components
import Button from "src/components/ui/button/button";
import SpeciesList from "src/components/ui/SpeciesList";
// redux
import { setLocation } from "src/redux/slices/auth";
import { HIDE_GEOLOCATION_MODAL_EVERYWHERE } from "src/constants/variables";
import { variablesStateKeySelector } from "src/redux/slices/variables";
// hooks
import { useModal, useWindowSize, useAppDispatch, useAppSelector } from "src/hooks";
import { useRouter } from "next/router";
// theme
import { EVariants } from "src/constants/variants";
// const
import { ENDPOINTS } from "src/constants/endpoints";
import { GEOLOCATION_MODAL } from "src/constants/modals";
// types
import { IGeometry } from "src/constants/coordinates";
import {
  Container,
  InputContainer,
  Line,
  ButtonContainer,
  ContainerMobile,
  MobileContainer,
} from "./styles";
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";

const LAPTOP = 1024;

const PlacesAutocompleteNoSSR = dynamic(() => import("src/components/ui/PlacesAutocomplete"), {
  ssr: true,
});

interface ISearchInputView {
  value?: string;
  onSearch: (place: google.maps.GeocoderResult | null, species: string[]) => Promise<void>;
  placeholder?: string;
  mobileContainerStyles?: object;
  showGeoModal?: boolean;
  onGeoModalClose?: () => void;
  onGeoModalSuccess?: () => void;
}

const SearchInputView: FC<ISearchInputView> = ({
  value,
  onSearch,
  placeholder,
  mobileContainerStyles,
  showGeoModal = true,
  onGeoModalSuccess,
  onGeoModalClose,
}) => {
  const { query, pathname } = useRouter();

  const size = useWindowSize();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const hideGeolocationModalEverywhere = useAppSelector(
    variablesStateKeySelector(HIDE_GEOLOCATION_MODAL_EVERYWHERE),
  );

  const dropdownOpenInput: any = useRef();

  const [searchedSpecies, setSearchedSpecies] = useState<string[]>(() => {
    if (query.species_items) {
      return Array.isArray(query.species_items) ? query.species_items : [query.species_items];
    }
    return [];
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [shouldShowGeolocationModalOnFocus, setShouldShowGeolocationModalOnFocus] =
    useState<boolean>(true);

  useEffect(() => {
    setShouldShowGeolocationModalOnFocus(showGeoModal);
  }, [showGeoModal]);

  useEffect(() => {
    setShouldShowGeolocationModalOnFocus(!hideGeolocationModalEverywhere);
  }, [hideGeolocationModalEverywhere]);

  const speciesResponse = useSWR(ENDPOINTS.GET.species, axios);

  const loadPlace = async (
    address: string,
    _placeID: string | null,
  ): Promise<google.maps.GeocoderResult | null> => {
    try {
      const [result] = await geocodeByAddress(address);
      return result;
    } catch (error) {
      console.error("loadPlace error: ", error);
      return null;
    }
  };

  /** Places autocomplete: START */
  const [searchedArea, setSearchedArea] = useState<google.maps.GeocoderResult | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (!searchValue) {
      setSearchValue(value || "");
    }
  }, [value]);

  const onSearchChange = (value: string): void => setSearchValue(value);

  const handleSelect = async (address: string, placeID: string, _obj?: any): Promise<void> => {
    onSearchChange(address);
    const result = await loadPlace(address, placeID);
    setSearchedArea(result);
  };
  /** Places autocomplete: FINISH */

  /** Dropdown logic: START */
  const openContainer = () => setIsDropdownOpen(true);

  const closeDropdownInput = (e: MouseEvent) => {
    setIsDropdownOpen(false);
    if (e) {
      e.stopPropagation();
    }
  };

  const handleClickInputOutside = (event: MouseEvent): void => {
    if (!dropdownOpenInput.current?.contains(event.target)) {
      closeDropdownInput(event);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickInputOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickInputOutside);
    };
  }, [isDropdownOpen]);
  /** Dropdown logic: FINISH */

  /** Species list logic: START */
  const handleSelectSpecies = (options: Array<{ label: string; value: string }>) => {
    // options is always an array, empty or full
    const newOptions = options.map((el) => el.value);
    setSearchedSpecies(newOptions);
  };

  // @ts-ignore
  const SPECIES_OPTIONS = (speciesResponse.data?.data || []).map((item) => ({
    label: item.name,
    value: item.name,
  }));
  /** Species list logic: FINISH */

  const handleSearch = async () => {
    let place = searchedArea;

    if (!!searchValue && searchValue !== searchedArea?.formatted_address) {
      // load new place based on input value
      place = await loadPlace(searchValue, null);
    }

    onSearch(place, searchedSpecies);
  };

  const handleEnterPress = async (address: string, placeID: string) => {
    try {
      onSearchChange(address);
      const place = await loadPlace(address, placeID);
      onSearch(place, searchedSpecies);
    } catch (error) {
      let errorMessage = "Failed to handleEnterPress in the search input";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      console.log(errorMessage);
    }
  };

  const onBlurCallback = async (address: string) => setSearchValue(address);

  const onFocusCallback = async () => {
    const isModalOpened = query.open_modal === GEOLOCATION_MODAL;
    if (!searchValue && shouldShowGeolocationModalOnFocus && !isModalOpened) {
      openModal(GEOLOCATION_MODAL, {
        onSuccess: async (userGeometry: IGeometry) => {
          const geocoder = new google.maps.Geocoder();
          const response = await geocoder.geocode({ location: userGeometry.center });
          const city = response.results.find((item) => item.types.includes("locality"));

          onSearchChange(city?.formatted_address || "");
          setSearchedArea(city || null);
          dispatch(setLocation(city?.formatted_address || ""));
          onGeoModalSuccess?.();
        },
        onDeny: () => {},
        onCloseCb: () => {
          setShouldShowGeolocationModalOnFocus(false);
          openContainer();
          onGeoModalClose?.();
        },
      });
    }
  };

  return (
    <Container>
      <>
        <InputContainer>
          <PlacesAutocompleteNoSSR
            value={searchValue}
            onChange={onSearchChange}
            onSelect={handleSelect}
            onEnterCallback={handleEnterPress}
            onBlurCallback={onBlurCallback}
            onFocusCallback={onFocusCallback}
          />
        </InputContainer>

        <Line />

        <SpeciesList
          // @ts-ignore
          onChange={handleSelectSpecies}
          options={SPECIES_OPTIONS}
          defaultValue={searchedSpecies.map((name) => ({ label: name, value: name }))}
          placeholder={placeholder}
        />

        <ButtonContainer>
          <Button
            variant={EVariants.PRIMARY}
            style={{ width: "100%", padding: "14px 35px", boxShadow: "none" }}
            onClick={() => {
              handleSearch();
              amplitudeEvent(formatEventName(pathname, "search", "search"));
            }}
          >
            Search
          </Button>
        </ButtonContainer>
      </>
    </Container>
  );
};

export default SearchInputView;
