import styled from "styled-components";
import { colors } from "src/theme/colors";
import device from "src/constants/media";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 11px 11px 11px;
  border-radius: 8px;
  background: ${colors.white}F2;
  z-index: 1;

  @media ${device.laptop} {
    flex-direction: row;
    padding: 0 8px 0 11px;
    background: ${colors.white};
  }

  #InputComponent_Wrapper {
    border: none;
  }
`;
export const InputContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid transparent !important;

  @media ${device.laptop} {
    max-width: 342px;
    border-bottom: none;
  }
`;
export const ContainerMobile = styled.div`
  width: 100%;
`;
export const Line = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    width: 0px;
    height: 32px;
    margin: 16px;
    background-color: ${colors.greyLight};
    border: 1px solid ${colors.greyLight} !important;
  }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 16px;
  @media ${device.laptop} {
    width: 120px;
    height: 48px;
    margin: 8px 0;
    padding-top: 0;
  }
`;

export const MobileContainer = styled.div<{ styles?: object }>`
  width: 100%;
`;
